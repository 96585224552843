import { createRouter, createWebHashHistory } from 'vue-router'
import NationalForm from '../views/NationalForm.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: NationalForm
  },
  {
    path: '/eu-form',
    name: 'eu-form',
    component: () => import(/* webpackChunkName: "about" */ '../views/EuForm.vue')
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('../views/ShowResults.vue'),
    props: true,
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router