<template>
  <form @submit.prevent="submitForm()" id="form" role="form" class="php-email-form">
    <div class="form-info">
      <p>
        Συμπληρώστε τα πεδία Επώνυμο, Όνομα, Όνομα Πατέρα, Έτος Γέννησης, Όνομα Μητέρας (προαιρετικό),
        ή μόνο τα πεδία Ε.Ε.Α. και Επώνυμο εφόσον τα γνωρίζετε.
      </p>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="eeaCode">Ειδικός Εκλογικός Αριθμός</label>
        <input v-model="form.eeaCode" type="text" name="eeaCode" class="form-control" id="eeaCode"
               placeholder="Εισάγετε 13 Ψηφία">
        <p class="validate">{{ eeaErrors }}</p>
      </div>
      <div class="form-group col-md-6">
        <label for="lastName">Επώνυμο</label>
        <input v-model="form.lastName" type="text" class="form-control" name="lastName" id="lastName"
               placeholder="Εισάγετε μόνο το πρώτο επώνυμο">
        <p class="validate">{{ errors.lastName }}</p>
      </div>
      <div class="form-group col-md-6">
        <label for="name">Όνομα</label>
        <input v-model="form.name" type="text" name="name" class="form-control" id="name"
               placeholder="Εισάγετε τουλάχιστον 2 χαρακτήρες">
        <p class="validate">{{ nameErrors }}</p>
      </div>
      <div class="form-group col-md-6">
        <label for="nameOfFather">Όνομα Πατέρα</label>
        <input v-model="form.nameOfFather" type="text"
               name="nameOfFather" class="form-control"
               id="nameOfFather" placeholder="Εισάγετε τουλάχιστον 2 χαρακτήρες">
        <p class="validate">{{ nameOfFatherErrors }}</p>
      </div>
      <div class="form-group col-md-6">
        <label for="nameOfMother">Όνομα Μητέρας</label>
        <input v-model="form.nameOfMother" type="text" name="nameOfMother" class="form-control"
               id="nameOfMother" placeholder="Προαιρετικό πεδίο">
      </div>
      <div class="form-group col-md-6">
        <label for="birthYear">Έτος Γέννησης</label>
        <input v-model="form.birthYear" type="text" class="form-control" name="birthYear" id="birthYear"
               placeholder="Εισάγετε 4 Ψηφία">
        <p class="validate">{{ birthYearErrors }}</p>
      </div>
    </div>
    <div class="form-group col-md-6" v-if="recaptchaEnabled && recaptchaSiteKey">
      <vue-recaptcha
          ref="recaptcha"
          :sitekey="this.recaptchaSiteKey"
          @verify="verifyReCaptcha"
      />
      <p class="validate display-block">{{ errors.recaptchaResponse + recaptchaResponseErrors }}</p>
    </div>
    <div class="d-flex justify-content-between">
      <div class="text-center">
        <a class="small" type="reset" @click="clearForm()">Καθαρισμός Φόρμας</a>
      </div>

      <div class="text-center">
        <button type="submit">
          Αναζήτηση
          <span id="searchButton" class="spinner-border ms-2 spinner-border-sm"
                :class="{ 'visually-hidden' : !searching }"
                role="status" aria-hidden="true">
          </span>
        </button>
      </div>
    </div>
    <div class="mt-5 text-center">
      <div style="white-space: pre-line;">{{this.searchFormDescription}}</div>
    </div>
    <p class="validate validate-general">{{ errors.general }}</p>
  </form>
</template>

<script>
import axios from 'axios'
import {VueRecaptcha} from 'vue-recaptcha';

export default {
  name: "HomeView",
  components: { VueRecaptcha },
  props : {
    recaptchaEnabled : Boolean,
    recaptchaSiteKey : String,
    showSpecialCatalogData : Boolean,
    searchFormDescription : String
  },
  data() {
    return {
      form: {
        eeaCode: "",
        name: "",
        lastName: "",
        nameOfFather: "",
        nameOfMother: "",
        birthYear: "",
        recaptchaResponse: "",
      },
      errors: {
        eeaCode: "",
        name: "",
        lastName: "",
        nameOfFather: "",
        birthYear: "",
        recaptchaResponse: "",
        general: "",
      },
      recaptchaResponseErrors: "",
      searching: false,
      clearFormActive: false,
      siteKey: ''
    };
  },
  computed: {
    eeaErrors: function () {
      let eeaCode = this.form.eeaCode
      if (eeaCode && (!Number(eeaCode) || eeaCode.length !== 13)) {
        return 'Παρακαλώ εισάγετε 13 αριθμούς ακριβώς';
      } else return ''
    },
    nameErrors: function () {
      return this.checkLetters(this.form.name)
    },
    nameOfFatherErrors: function () {
      return this.checkLetters(this.form.nameOfFather)
    },
    birthYearErrors: function () {
      let birthYear = this.form.birthYear
      let birthYearErrors = ""
      const date = new Date();
      // bigger than 1900 and for persons > 16 years old
      if (birthYear) {
        if (!Number(birthYear) || birthYear.length !== 4 || birthYear < 1900 || birthYear > date.getFullYear() - 16) {
          birthYearErrors = 'Παρακαλώ ελέγξτε τα στοιχεία σας'
        }
      }
      return birthYearErrors
    }
  },
  methods: {
    verifyReCaptcha(res){
      this.form.recaptchaResponse = res
      this.errors.recaptchaResponse = ''
    },
    clearForm() {
      this.form.eeaCode = "";
      this.form.name = "";
      this.form.nameOfFather = "";
      this.form.nameOfMother = "";
      this.form.lastName = "";
      this.form.birthYear = "";
    },
    customValidate() {
      let form = this.form
      Object.keys(this.form).forEach(el => {
        if(el !== 'recaptchaResponse'){
          this.form[el] = this.clearFromAccents(this.form[el]).toUpperCase()
        }
      })
      let validationError = false
      //first check for lastName which is required for both search types
      if (!form.lastName) {
        this.errors.lastName = 'Υποχρεωτικό πεδίο'
        validationError = true;
      } else {
        this.errors.lastName = ''
      }
      if(!form.recaptchaResponse && this.recaptchaEnabled){
        this.errors.recaptchaResponse = 'Παρακαλώ συμπληρώστε το reCaptcha'
        validationError = true;
      }
      if(validationError){return false}

      return !!((form.eeaCode && !this.eeaErrors) ||
          (form.name && !this.nameErrors &&
              form.nameOfFather && !this.nameOfFatherErrors &&
              form.birthYear && !this.birthYearErrors));
    },
    submitForm(){
      this.searching = true
      if (this.customValidate()) {
        this.errors.general = ''
        //put out accents from greek strings
        Object.keys(this.form).forEach(el => {
          if(el !== 'recaptchaResponse') {
            this.form[el] = this.clearFromAccents(this.form[el]).toUpperCase()
          }
        })
        axios.post(`${process.env.VUE_APP_API_URL}index`, {
          eeaCode: this.form.eeaCode,
          name: this.form.name,
          lastName: this.form.lastName,
          nameOfFather: this.form.nameOfFather,
          nameOfMother: this.form.nameOfMother,
          birthYear: this.form.birthYear,
          recaptchaResponse: this.form.recaptchaResponse,
        }).then((response) => {
          if(response.data.message === 'captchaNotPassed'){
            this.$refs.recaptcha?.reset();
            this.recaptchaResponseErrors = 'Παρακαλώ επιβεβαιώστε πάλι'
            this.errors.recaptchaResponse = ''
          }

          this.searching = false
          if (response.data && response.data.length) {
            this.$router.push({
              name: 'results',
              params: {data: JSON.stringify(response.data),showSpecialCatalogData: this.showSpecialCatalogData, searchFormDescription: this.searchFormDescription}
            })
          } else {
            this.errors.general = 'Δεν βρέθηκαν εγγραφές!'
          }
        }).catch(() => {
          this.searching = false
          this.errors.general = 'Παρουσιάστηκε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά!'
        });
      } else {
        this.searching = false
        this.errors.general = 'Παρακαλώ συμπληρώστε περισσότερα πεδία'
      }
    },
    checkLetters(name){
      if (name && name.length < 2) {
        return 'Παρακαλώ εισάγετε τουλάχιστον 2 χαρακτήρες'
      } else return ''
    },
    clearFromAccents(text){
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    },
  },
};
</script>
