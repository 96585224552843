import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueGtag from 'vue-gtag'

let app = createApp(App)

app.use(VueGtag,{
    config: { id: 'G-VSRGZ6DPPC' }
})

app.use(router).mount('#app')
