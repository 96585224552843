<template>
  <footer>

    <div class="copyright">© Copyright 2023 GOV.GR - Υλοποίηση για το <a href="https://www.ypes.gr/" target="_blank" class="pointer">Υπουργείο Εσωτερικών</a></div>

  </footer>
</template>


<script>
export default {
  name:'MyFooter'
}
</script>

<style>
footer{
  width: 100%;
  height: 70px;
  position: relative;
  bottom:0;
  background: #003476;
  color: white;
  margin-top:83px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.credits{
  font-style: italic;
  font-weight: bold;
  font-size: 11px;
  display:flex;
  align-items: center;
}

.copyright{
  font-size: 14px;
}

.pointer{
  cursor: pointer;
}

.slg-logo{
  height: 25px;
}

</style>
