<template>


  <section id="app-vue" class="app-vue ">
    <MyHeader />

    <AccessibilityToolbar></AccessibilityToolbar>
    <div class="container" data-aos="fade-up">
<!--      <div class="section-title pb-0 pb-lg-3">-->
<!--        <h2>ΜΑΘΕ ΠΟΥ ΨΗΦΙΖΕΙΣ</h2>-->
<!--      </div>-->
      <ul v-if="$route.name !== 'results'" id="app-vue-flters"
          class="d-flex justify-content-center p-0" data-aos="fade-up"
          data-aos-delay="100">
        <router-link to="/">
          <li id="greek-tab" :class="{ 'filter-active' : grActive }">
            <p class="m-1 tab-app">ΕΛΛΗΝΑΣ ΠΟΛΙΤΗΣ</p>
          </li>
        </router-link>
        <router-link to="/eu-form">
          <li id="eu-tab" :class="{ 'filter-active' : euActive }">
            <p class="m-1 tab-app">ΕΥΡΩΠΑΙΟΣ ΠΟΛΙΤΗΣ</p>
          </li>
        </router-link>
      </ul>
      <section id="left-section" class="left-section content p-0">
        <div class="container" data-aos="fade-up">
          <div class="d-flex flex-lg-row flex-column-reverse">
            <div class="d-lg-block col-lg-5 px-lg-2 py-2 d-flex align-items-stretch">
              <div class="info">
                <div class="vertical-message-image-wrapper">
                  <h2 v-if="this.elections" class="vertical-message">
                    {{ this.elections }}
                  </h2>
                  <img style="max-width: 100%;" alt=""
                       src="./assets/ekloges.png">
                </div>
                <p  class="info-left border-info" style="white-space: pre-line;" v-html="bannerFormDescription"></p>
              </div>
            </div>
            <div class="col-lg-7 mt-0 px-lg-2 py-2 d-flex align-items-stretch">
              <router-view :recaptchaSiteKey="this.recaptchaSiteKey"
                           :recaptchaEnabled="this.recaptchaEnabled"
                           :showSpecialCatalogData="this.showSpecialCatalogData"
                           :searchFormDescription="this.searchFormDescription"
              />
            </div>
          </div>
        </div>
      </section><!-- End Section -->
    </div>
    <MyFooter />
  </section>


</template>

<script>
import AccessibilityToolbar from "vue-accessibility-toolbar"
import "bootstrap-icons/font/bootstrap-icons.css"
import "glightbox/dist/js/glightbox.min.js"
import "waypoints/lib/noframework.waypoints.min.js"
import "swiper/bundle"
import "isotope-layout/js/isotope"
import "aos/dist/aos"
import axios from 'axios'
import MyFooter from "@/components/MyFooter.vue";
import MyHeader from "@/components/MyHeader.vue";


export default {
  components: {
    MyFooter,
    MyHeader,
    AccessibilityToolbar


  },
  data() {
    return {
      elections: "",
      lastUpdated: "",
      catalogUpdated: "",
      recaptchaSiteKey: "",
      recaptchaEnabled: true,
      showSpecialCatalogData:false,
      bannerFormDescription: "",
      searchFormDescription: ""
    };
  },
   created() {
    this.initial();
  },
  computed: {
    grActive() {
      return this.$route.name === 'home'
    },
    euActive() {
      return this.$route.name === 'eu-form'
    }
  },
    methods: {
    initial () {
      axios.get(`${process.env.VUE_APP_API_URL}`).then((response) => {
        if (response.data) {
          this.elections = response.data.elections.description;
          this.lastUpdated = response.data.elections.LAST_UPDATE;
          this.catalogUpdated = response.data.elections.CATALOG_LAST_UPDATE;

          this.showSpecialCatalogData = response.data.elections.show_special_catalog_data == 1;
          this.searchFormDescription = response.data.elections.search_form_description;
          this.bannerFormDescription = response.data.elections.banner_form_description;

          window.preElection = response.data.pre_election.pre_election

/*          window.NO_RECAPTCHA = response.data.NO_RECAPTCHA
          window.recaptchaSiteKey = response.data.RECAPTCHA_SITE_KEY*/
          this.recaptchaSiteKey = response.data.RECAPTCHA_SITE_KEY
          this.recaptchaEnabled = !response.data.NO_RECAPTCHA
        }
      }).catch(() => {
        this.errors.general = 'Παρουσιάστηκε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά!'
      })
    }

  }
}
</script>

<style>
.vertical-message-image-wrapper{
  display: flex;
  justify-content: center;
}
.vertical-message{
  letter-spacing: 2px;
  margin-left: 0;
  writing-mode: vertical-rl;
  transform: scale(-1);
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.tab-app {
  /*font-size: 24px;*/
  /*margin: 0 0 20px 0;*/
  /*padding: 0;*/
  line-height: 1;
  font-weight: 800 !important;
  /*color: #37517e ;*/
}

.info-left {
  font-family: "Open Sans", sans-serif !important;
  color: #444444 !important;
  text-align: center !important;
  margin-top: 2rem !important;
  padding: 0 !important;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
  url('~@/assets/fonts/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2') format("woff2");
}

.container{
  position:relative;
}


</style>
